import React, { useEffect, useState } from "react";
import SettingService from "../../../../../services/SettingServices";
const BUSubscription = ({ profileData }) => {
  const [stripeData, setstripeData] = useState();
  const [remainingCredits, setremainingCredits] = useState({});
  const [property, setproperty] = useState();
  const [inspector, setinspector] = useState();
  const [basic, setbasic] = useState();
  const [loader, setloader] = useState(true);
  useEffect(() => {
    // setloader(true);
    // SettingService.getSubscriptionPrices()
    //   .then((x) => {
    //     console.log(x.data);

    //     setstripeData(x.data);
    //   })
    //   .catch((err) => console.log(err))
    //   .finally(() => setloader(false));
  }, []);

  useEffect(() => {
    // SettingService.getRemainingCredits()
    //   .then((x) => {
    //     setremainingCredits(x?.data?.credits);
    //   })
    //   .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    stripeData?.map((d) => {
      return (
        (d.metadata.product_type === "basic_subscription" &&
          setbasic((d.prices[0]?.unit_amount / 100).toFixed(2))) ||
        (d.metadata.product_type === "inspector_credits" &&
          setinspector((d.prices[0]?.unit_amount / 100).toFixed(2))) ||
        (d.metadata.product_type === "inspection_credits" &&
          setproperty((d.prices[0]?.unit_amount / 100).toFixed(2)))
      );
    });
  }, [stripeData]);
  return (
    <div className="row ">
      <div className="col-md-7 billing-table mt-3">
        <h4 className="text-justify font-weight-bold">Subscription</h4>
        <table className="table table-responsive-md">
          <thead>
            <tr>
              <th>Plan Name</th>
              <th>Monthly Cost </th>
              <th>Inspector Credit</th>
              <th>Inspection Credit</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>Basic</p>
              </td>
              <td>
                <p>${basic}</p>{" "}
              </td>
              <td>
                <p>${inspector}</p>{" "}
              </td>
              <td>
                <p>${property}</p>{" "}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="col-sm-6 col-md-2 mt-3">
        <div className="mt-4">
          <p className="text-justify font-weight-bold m-0 heading-rem">
            Remaining{" "}
          </p>
          <p className="text-justify font-weight-bold heading-rem ">
            Inspector credits
          </p>
          <div className="bill-amount">
            <span className="remaining-bill">
              {profileData ? profileData.credits?.inspector_credits : "0"}
            </span>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-md mt-3">
        <div className="mt-4 ml-3">
          <p className="text-justify font-weight-bold m-0 heading-rem">
            Remaining{" "}
          </p>
          <p className="text-justify font-weight-bold heading-rem ">
            Inspection credits
          </p>
          <div className="bill-amount">
            <span className="remaining-bill">
              {profileData ? profileData.credits?.inspection_credits : "0"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BUSubscription;
